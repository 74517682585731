import { Grid, Title, Text, Image, Loader, AspectRatio } from "@mantine/core";
import { useEffect, useState } from "react";
import { useProjects } from "../../api/ProjectsContext";
import { useParams } from "react-router-dom";
import CarouselLayout from "../../components/carousel/CarouselLayout";

function Project() {
  const params = useParams();
  const { projectsState, getProjects, getProjectImages } = useProjects();
  const [project, setProject] = useState();

  useEffect(() => {
    if (!projectsState.loading && !projectsState.data.length) {
      loadProjects();
    } else if (!projectsState.loading && projectsState.data.length) {
      const match = projectsState.data.filter(
        (x) => x.identifier === params.project
      )[0];
      setProject(match);
    }
  }, [projectsState.data.length]);

  useEffect(() => {
    if (project) {
      loadImages(project.identifier);
    }
  }, [project]);

  const loadProjects = async () => {
    await getProjects("projects").then(() => {
      const match = projectsState.data.filter(
        (x) => x.identifier === params.project
      )[0];
      setProject(match);
    });
  };

  const loadImages = async (identifier) => {
    await getProjectImages(identifier);
  };

  return (
    <div style={container}>
      <Grid gutter={50} style={{ width: "100%" }}>
      {project ? 
        <Grid.Col xl={4} lg={4} md={4} sm={4} xs={12} orderSm={2} orderMd={2}>
          <Title style={headerStyle}>The Story</Title>
          <Title size="h2" style={titleStyle}>
            {project.title}
          </Title>
          {project.description.map((item, index) => <div key={index}>
            {item.title ? <Text size="lg" style={descriptionTitleStyle}>
              {item.title}
            </Text> : null}
            <Text size="lg" style={descriptionContentStyle}>
            {item.content}
          </Text>
          </div>)}
        </Grid.Col>
        : null}
        <Grid.Col xl={8} lg={8} md={8} sm={8} xs={12} orderSm={1} orderMd={1}>
            {projectsState.loading ? (
              <div className="loader">
                <Loader />
              </div>
            ) : null}
            {projectsState.images && projectsState.images.id === project?.identifier
            ? <CarouselLayout data={projectsState.images.images} slideSize="50%" height={750} mdSlideSize="100%" lgSlideSize="100%"/>
              : null}
        </Grid.Col>
      </Grid>
    </div>
  );
}
export default Project;

const container = {
  display: "flex",
  flex: 1,
  width: "100%",
};

const titleStyle = {
  paddingBottom: "0.5em",
  fontFamily: "Lato",
};

const descriptionContentStyle = {
  fontFamily: "Lato",
  whiteSpace: "pre-wrap",
  paddingBottom: "1.5em"
};

const headerStyle = {
  fontFamily: "Dancing Script",
  fontSize: "3.5em",
  paddingBottom: "0.5em",
  color: "#044484",
};

const descriptionTitleStyle = {
  color: "#044484",
  fontWeight: "bold",
  fontFamily: "Lato",
  paddingBottom: "0.5em"
}
