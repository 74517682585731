export default {
  about: {
    title: "About Us",
    intro:
      "Refresh, restore, and repurpose vintage treasures and antiques. \nWe are both collectors and sellers of unique items, curious curiosities and old things.",
    description: [
      {
        header: "Kaye",
        content:
          "Growing up in Western Massachusetts, Kaye worked for an international non-profit organization for over 30 years as a logistics coordinator and event planner.  During this time, she was also restoring and refinishing antique trunks for resale and custom orders.  From a love of antiques at an early age, Kaye has been buying and selling antiques, and helping folks find new homes for their treasures.  Most recently, she left her full-time job to devote her energies to Blue Caboose Station and work with Bruce. \n\nKaye is also a MA Licensed Realtor with Brick-and-Mortar Realty in Amherst, MA.  Her familiarity with the area and her many connections offers buyers and sellers a kind and compassionate agent to help them find or sell their home.",
      },
      {
        header: "Bruce",
        content:
          "Since Bruce was 8, he has been fixing things.  From custom yachts to numerous home renovations and everything in between.  His experience includes automotive repair, making prototype mechanical devices, timber framer and restoration of antique homes, yacht building, restaurant owner, and airplane mechanic.  Now fast forward, both he and Kaye are creating amazing pieces of useful furniture, restoring all kinds of things for people’s homes and offices at their workshop Blue Caboose Station.  Bruce’s passion is to repair something he has never repaired or fixed before.  Items most recently worked on are National Cash Registers and Manley Popcorn Theater Machines.  He also enjoys carnival items and vintage amusement park games.",
      },
      {
        header: "Blue Caboose Station",
        content:
          "Blue Caboose Station is where Bruce, Kaye, Rocky and Sierra reside.  It is also the home of two 1959 Boston & Maine Railroad Cabooses that sit on track next to our workshop, a building designed by Bruce inspired by the old Boston & Maine Train Station in Amherst, where Amherst Farmers Supply currently exists.  From a lifelong dream of a young boy to own a caboose, that wish came true in 2015 when the then owner accepted Bruce’s offer to purchase them from their resting place in Deerfield, MA. Bruce designed a dolly to move them, out of an old set of tractor trailer axles. \n\nThe workshop is where it all comes together, transforming metal and wood to unique, decorative, and functional items for your home.  Our inventory is always changing as we frequent area estate sales and auctions to bring fresh antiques and vintage items to the shop. \n\nSierra and Rocky are Blue Caboose Stations ambassadors and our best friends. They travel most places with us, and are always  a part of our day while we work in and around the workshop. \n\nYou are welcome to sign up for our monthly newsletter & stay up to date on fresh arrivals, sales, and more by going to our contact us tab. Thanks for stopping by Blue Caboose Station. ",
      },
    ],
  },
  home: {
    title: "Blue Caboose Station",
    subtitle:
      "Refresh, restore, and repurpose vintage treasures and antiques. Collectors and sellers of unique items.",
    description:
      "In Western Massachusetts you will find Blue Caboose Station. Our workshop is where we refresh, restore, and repurpose vintage treasures and have an assortment of unique antiques.\n\nThree times a year, we participate in the famous Brimfield MA Antique show that takes place in May, July, and September. Our booth is located at the New England Motel Field, behind the food court. If you are unable to attend Brimfield, you can conveniently browse our current offerings through our online store, linked above.",
    newsletter:
      "Sign up for our monthly newsletter & stay up to date on fresh arrivals, sales, and more",
    descriptionTitle: "Who we are",
    socialMediaText:
      "To stay updated on our projects and fresh items for sale, we invite you to follow us on our various social media platforms.",
  },
  contact: {
    title: "Get in touch with us.",
    subtitle:
      " We would love to hear from you. Please provide your information and we will reach out to you as soon as possible.",
    modalError:
      "There was an error submitting your contact request. Please try again later.",
    modalSuccess: "Your contact request has successfully been submitted!",
    nameValidation: "Please provide first name and last name.",
    emailValidation: "Invalid email address.",
    phoneNumberValidation:
      "Please enter a phone number with the following format: XXX-XXX-XXXX",
  },
  projectCard: {
    buttonText: "Read More",
  },
};
