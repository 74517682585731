import { Text } from "@mantine/core";
import Socials from "../social/socials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import "./Footer.css";
import { Link } from "react-router-dom";
function Footer() {
  return (
      <div className="footerContainer">
        <div className="footerItems">
          <Text className="footerText">All Rights Reserved 2025</Text>
        </div>
        <div className="footerItems">
          <FontAwesomeIcon icon={faLocationDot} style={iconStyle} size="lg" />
          <Text className="footerText">Belchertown, MA 01007</Text>
        </div>
        <div className="footerItems">
          <FontAwesomeIcon icon={faPhone} style={iconStyle} size="lg" />
          <a href="tel:+14136872245" className="footerText">(413) 687-2245</a>
        </div>
        <div className="footerItems">
          <FontAwesomeIcon icon={faEnvelope} style={iconStyle} size="lg" />
          <Link
            to='#'
            onClick={(e) => {
                window.location.href = "mailto:kaye@bluecaboosestation.com";
                e.preventDefault();
            }}
        className="footerText">kaye@bluecaboosestation.com</Link>
        </div>
        <div className="footerItems">
          <Socials iconSize="lg" iconStyle={socialIconStyle} iconColor="#FFFFFF"/>
        </div>
      </div>
  );
}

export default Footer;

const socialIconStyle = {
  marginTop: 10,
  marginBottom: 10,
  width: '4em',
  color: "#FFFFFF",    
};

const iconStyle = { color: "#FFFFFF", paddingRight: "0.5em",  };
